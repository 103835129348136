<template>
  <div class="wh-100 position-relative" v-if="!!auction">
    <div class="invoice-canceled" v-if="invoiceCanceled">
      <h1 class="text-danger text-center flex-vertical-center">الفاتورة ملغية</h1>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src="../../assets/images/logo.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-md-6">
          <h6 class="text-right flex-vertical-center"><strong>شركة برهومة والسكران</strong></h6>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 py-0">
          <h6>التاريخ : <label class="border-bottom-dotted">{{ todayDate }}</label></h6>
        </div>
        <div class="col-sm-4 py-0">
          <h5 class="pm-0 text-center"><label class="border-bottom border-dark">فاتورة Invoice</label></h5>
        </div>
        <div class="col-sm-4 py-0">
          <label class="py-1 m-0 rounded-pill bg-dark text-light d-flex justify-content-around">
            <span>الرقم الضريبي</span>
            <span style="letter-spacing: 2mm">22524347</span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <h6>مطلوب من السادة :
            <label class="border-bottom-dotted" v-if="!!companyLink">
              <router-link :to="companyLink" class="link">{{ auction.company }}</router-link>
            </label>
          </h6>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4" v-if="!!invoice">
          <p class="pm-0"><span>رقم الفاتور: </span>&nbsp;<span class="font-weight-bolder">{{ invoice.number }}</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
          <button type="button" class="btn btn-info rounded-0 btn-sm" @click="refresh" title="تحديث">
            <i class="fas fa-sync"></i>
          </button>
        </div>
      </div>
      <div class="row" v-if="!$store.getters.shouldReload">
        <div class="col-sm-12 jumbotron" v-if="!companyId">
          <h6>يرجى تحديد شركة التأمين الخاصة بالمزاد</h6>
        </div>
        <div class="col-sm-12 jumbotron" v-else-if="!currentPrice">
          <h6>سعر المزاد غير محدد</h6>
        </div>
        <div class="col-md-12 jumbotron" v-else-if="!sold && emptyEService">
          <h6>
            <label>
              <span>لم يتم تحديد سعر عمولة المزاد بسعر البيع</span>&nbsp;
              <span>({{ currentPrice }})</span>&nbsp;
              <span>دينار</span>
            </label>&nbsp;
            <router-link :to="{name: 'fees.pricing', params: {company: companyId}}">تعديل العمولات</router-link>
          </h6>
        </div>
        <div class="col-md-12 jumbotron" v-else-if="transferSourceNotDefined">
          <h6>لم يتم تحديد مكان النقل</h6>
        </div>
        <div class="col-md-12 jumbotron" v-else-if="isTransferred && emptyWinchService">
          <h6>
            <span>لم يتم تحديد سعر النقل من ({{ auction.city }})</span>&nbsp;
            <router-link :to="{name: 'winch.pricing', params: {company: companyId}}">تعديل الاسعار</router-link>
          </h6>
        </div>
        <div class="col-md-12" v-else>
          <div class="table-responsive">
            <table class="table table-bordered h-100 w-100 text-center">
              <tr class="bg-bb">
                <th class="w-25" colspan="2"><strong>إجمالي السعر</strong></th>
                <th class="w-50" colspan="2"><strong
                  class="d-flex justify-content-center pt-0">
                  <span class="">البيان</span>&nbsp;<span>Description</span></strong>
                </th>
                <th class="w-15" colspan="2"><strong>ملاحظات Notes</strong></th>
                <th class="w-10" colspan="2"><strong>تعديل</strong></th>
              </tr>
              <invoice-row row-class="bg-bb" :item="invoiceHeader" :header="true"></invoice-row>
              <invoice-row v-for="item in items" :key="item.id" :item="item" :edit="true" @deleted="deleted"></invoice-row>
              <invoice-row v-for="(item, index) in vehicleData" :key="(`${index}${item.desc}`)" :item="item"></invoice-row>
              <invoice-row v-for="(item, index) in emptyItems" :key="`${item}empty-item${index}`" :item="item"></invoice-row>
              <tr class="bg-bb">
                <th class="py-1"><small><strong>{{ sum.fills }}</strong></small></th>
                <th class="py-1"><small><strong>{{ sum.dinar }}</strong></small></th>
                <th class="py-1 text-left" colspan="6">المجموع:</th>
              </tr>
              <tr class="bg-bb">
                <th class="py-1"><small><strong>{{ tax.fills }}</strong></small></th>
                <th class="py-1"><small><strong>{{ tax.dinar }}</strong></small></th>
                <th class="py-1 text-left" colspan="6">16% ضريبة المبيعات:</th>
              </tr>
              <tr class="bg-bb">
                <th class="py-1"><small><strong>{{ total.fills }}</strong></small></th>
                <th class="py-1"><small><strong>{{ total.dinar }}</strong></small></th>
                <th class="py-1 text-left" colspan="6">المجموع الكلي:</th>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <h6 v-if="invoiceCanceled" class="text-center text-danger">الفاتورة ملغية لا يمكن التعديل</h6>
          <h6 v-else-if="invoiceChecked" class="text-center text-danger">الفاتورة مدققة لا يمكن التعديل</h6>
          <h6 v-else-if="!canControlInvoice" class="text-center text-danger">
            <span>لا يمكن إصدار فاتورة للمزاد وهو في الحالة </span>
            <strong>{{ auctionStatus.text }}</strong>
          </h6>
          <div v-else-if="(!emptyEService) && (!isTransferred || !emptyWinchService)" class="w-100 d-flex justify-content-end">
            <modal-btn btn-class="btn-info mr-1" target="#add-invoice-item">إضافة</modal-btn>
            <modal-btn target="#store-invoice-modal">حفظ</modal-btn>
          </div>
        </div>
        <div class="col-sm-12">
          <h5>ملاحظات</h5>
          <p class="pm-0"><span>سعر البيع: </span>&nbsp;
            <span class="font-weight-bolder">{{ currentPrice }}</span>&nbsp;
            <span v-if="!!currentPrice">دينار</span>
          </p>
          <p class="pm-0">
            <span>إسم المزاد: </span>&nbsp;
            <router-link :to="auctionLink" class="link danger">{{ auction.name }}</router-link>
          </p>
          <label>
            <span></span>
            <strong></strong>
          </label>
        </div>
      </div>
      <confirm-model
        title="إضافة بند"
        confirm-text="إضافة"
        target-id="add-invoice-item"
        :centered="true"
        confirm-btn="btn-secondary"
        @confirmed="saveItem">
        <form id="invoice-form">
          <div class="row p-1">
            <div class="col-md-12">
              <label>نوع الخدمة</label>
              <select class="selectpicker form-control" id="service" v-model.number="service" title="اختر بند جديد">
                <option value="1" :disabled="!eServices || eServices.disabled">{{ eServices.desc }}</option>
                <option value="2" :disabled="!winchService || winchService.disabled">{{ winchService.desc }}</option>
                <option value="3">أخرى</option>
              </select>
            </div>
          </div>
          <div v-if="service === 3">
            <div class="row p-1">
              <div class="col-md-3">
                <label>بند جديد</label>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-sm-12">
                    <label>السعر</label>
                    <form-input type="number" placeholder="السعر" id="price"></form-input>
                  </div>
                  <div class="col-sm-12">
                    <label>الخدمة</label>
                    <form-input placeholder="إسم الخدمة" id="desc"></form-input>
                  </div>
                  <div class="col-sm-12">
                    <label>ملاحظة</label>
                    <form-text-area id="note" placeholder="ملاحظة"></form-text-area>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </confirm-model>
      <confirm-model
        footer-classes="p-1"
        title="حفظ الفاتورة"
        confirm-btn="btn-secondary"
        confirm-text="حفظ"
        target-id="store-invoice-modal"
        @confirmed="storeInvoice">
        <h6>هل أنت متأكد من حفظ الفاتورة ؟</h6>
        <small class="link danger" v-if="canCreateInvoice">في حال حفظ الفاتورة سوف ينتقل المزاد الحالي الى مزادات تدقيق الفواتير</small>
      </confirm-model>
    </div>
  </div>
</template>
<script>
import ModalBtn from "@/components/UI/ModalBtn"
import ConfirmModel from "@/components/UI/ConfirmModel"
import InvoiceRow from "@/components/Invoices/InvoiceRow"
import FormInput from "@/components/Forms/FormInput"
import FormTextArea from "@/components/Forms/FormTextArea"
import Storage from "@/common/Storage"
import random from "@/common/random"
import types from "@/common/types"

export default {
  components: {FormTextArea, FormInput, InvoiceRow, ConfirmModel, ModalBtn},
  props: {
    invoiceId: {},
    auctionId: {},
    url: {required: true},
    method: {default: 'GET'},
  },
  data() {
    return {
      items: [],
      service: '',
      invoice: '',
      auction: '',
      sum: {},
      tax: {},
      total: {},
    }
  },
  computed: {
    finalAuctionId() {
      return this.auction?.slug ?? this.auctionId ?? ''
    },
    auctionLink() {
      return !!this.finalAuctionId ? {name: 'auctions.status', params: {auction: this.finalAuctionId}} : '#'
    },
    currentPrice() {
      return this.auction.current_price || ''
    },
    companyId() {
      return this.auction.company_id || ''
    },
    todayDate() {
      const date = new Date()
      return date.getFullYear() + ' / ' + (date.getMonth() + 1) + ' / ' + date.getDate()
    },
    companyLink() {
      return !!this.auction ? {name: 'companies.pricing', params: {company: this.auction.company_id}} : ''
    },
    invoiceChecked() {
      return !!this.invoice && !!types.checkY(this.invoice.checked)
    },
    invoiceCanceled() {
      return !!this.invoice && !!types.checkY(this.invoice.canceled)
    },
    auctionStatus() {
      return this.auction.status || ''
    },
    canControlInvoice() {
      return this.canCreateInvoice || this.canUpdateInvoice
    },
    sold() {
      return !!this.auction && types.checkY(this.auction.sold)
    },
    soldButTransferred() {
      return !!this.auction && types.checkY(this.auction.sold) && types.checkY(this.auction.transferred)
    },
    canCreateInvoice() {
      return !!this.auctionStatus && (parseInt(this.auctionStatus.number) === 4 || this.soldButTransferred)
    },
    canUpdateInvoice() {
      return !!this.auctionStatus && (parseInt(this.auctionStatus.number) === 5 || this.soldButTransferred)
    },
    transferSourceNotDefined() {
      return (this.isTransferred) && (!this.auction.city_id || !this.auction.city)
    },
    isTransferred() {
      return !!this.auction && types.checkY(this.auction.transferred)
    },
    emptyItems() {
      return (!this.items) ? 5 : ((this.items.length < 5) ? (5 - this.items.length) : 0)
    },
    eServices() {
      const check = !!this.auction && !!this.auction.fee
      let dinar = check ? this.auction.fee : ''
      let desc = check ? 'خدمات إلكترونية' : 'لم يتم تحديد عمولة المزاد'
      return {dinar, desc, note: '', disabled: !check, type: 1}
    },
    winchService() {
      let dinar = '', desc = '', note = '', disabled = true, type = 2
      if (!!this.auction) {
        if (!types.checkY(this.auction.transferred)) {
          desc = '(ونش) تم نقل المركبة من قبل ونش خارجي'
        } else {
          if (!this.auction.city_id) {
            desc = '(ونش) لم يتم تحديد مصدر نقل المركبة'
          } else {
            dinar = !!this.auction.winch ? this.auction.winch : ''
            desc = !dinar ? 'ونش / لم يتم تحديد سعر النقل' : 'ونش / '
            if (!!this.auction.city)
              desc = `${desc} ( ${this.auction.city} )`
            disabled = !dinar
          }
        }
      }
      return {dinar, desc, note, disabled, type}
    },
    invoiceHeader() {
      return {fills: "فلس Fils", dinar: "دينار JOD"}
    },
    vehicleData() {
      return !this.auction ? [] : [{desc: this.auction.claim_no}, {desc: this.auction.plate_no}]
    },
    emptyEService() {
      return !this.eServices.dinar
    },
    emptyWinchService() {
      return !this.winchService.dinar
    }
  },
  methods: {
    async setInvoice() {
      const response = await http.send(this.url, {_method: this.method, invoice: this.invoiceId, auction: this.auctionId})
      if (http.responseAccepted(response))
        this.setData(response.data)
    },
    async storeInvoice() {
      if (!this.items || this.items.length === 0)
        return http.popupMessage('error', 'لم يتم إدخال اي بند')
      this.hideModal('#store-invoice-modal')
      if (!this.finalAuctionId)
        return http.popupMessage('error', 'لم يتم تحديد المزاد')
      const response = await http.send('invoices', {
        auction: this.finalAuctionId,
        company: this.companyId,
        items: this.prepareItems(),
      })
      if (http.responseAccepted(response)) {
        this.setData(response.data)
        http.popupMessage('success', messages.saved)
      }
    },
    prepareItems() {
      const items = []
      if (!types.isObject(this.items)) return items
      this.items.map((item) => {
        if (!!item && Object.entries(item).length > 0) {
          items.push({
            desc: item.desc,
            dinar: item.dinar,
            fills: item.fills,
            note: item.note,
            type: item.type
          })
        }
      })
      return items
    },
    setData(data) {
      if (!data) return
      this.invoice = data.invoice
      this.items = types.objectToArray(data.items) || []
      this.auction = data.auction
      if (this.canCreateInvoice) {
        if (this.soldButTransferred) {
          this.pushWinch()
        } else {
          this.pushEService()
          this.pushWinch()
        }
      }
      this.calculateTotal()
    },
    saveItem() {
      if (!this.service)
        return http.popupMessage('info', 'يرجى تحديد الخدمة')
      if (this.service === 3) {
        const dinar = $_('#price').val()
        const desc = $_('#desc').val()
        const note = $_('#note').val()
        if (!dinar)
          return http.popupMessage('info', 'يرجى تعبئة سعر البند')
        if (!desc)
          return http.popupMessage('info', 'يرجى تعبئة إسم البند')
        return this.pushItem({dinar, desc, note})
      }
      const serviceAdded = this.checkServiceAdded(this.service)
      if (!serviceAdded.status) {
        if (this.service === 1)
          return this.pushItem(this.eServices)
        if (this.service === 2)
          return this.pushItem(this.winchService)
      }
      this.hideModal('#add-invoice-item')
    },
    checkServiceAdded(service) {
      let added = {status: false, msg: ''}
      if (!this.items || this.items.length === 0)
        return added
      const found = !!this.items.find(item => {
        return parseInt(item.type) === parseInt(service)
      })
      if (!found)
        return added
      added.status = true
      if (service === 1) {
        added.msg = 'تم إضافة خدمات إلكترونية بالفعل'
      } else if (service === 2) {
        added.msg = 'تم إضافة خدمة النقل بالونش بالفعل'
      }
      return added
    },
    pushItem(item) {
      this.hideModal('#add-invoice-item')
      if (!!item.dinar) {
        const fills = parseFloat(item.dinar).toFixed(3).toString().split('.')[1]
        item = {
          ...item, // copy item
          id: random.string(10),
          fills: parseInt(fills),
          dinar: parseInt(item.dinar),
          type: this.service,
        }
        this.items.push(item)
        this.calculateTotal()
        this.emptyServiceMenu()
      } else {
        http.popupMessage('error', 'سعر الخدمة غير معرف')
      }
    },
    calculateTotal() {
      const items = this.items
      if (!!items && items.length > 0) {
        this.sum.fills = 0
        this.sum.dinar = 0
        items.forEach(item => {
          this.sum.fills += parseFloat(item.fills)
          this.sum.dinar += parseFloat(item.dinar)
        })
        this.sum = this.sum.dinar + this.sum.fills / 1000
        this.tax = this.sum * 0.16
        this.total = this.sum + this.tax
        const data = ['sum', 'tax', 'total']
        for (const sum in data) {
          const dinar = this[data[sum]].toFixed(3).toString().split('.')[0]
          const fills = this[data[sum]].toFixed(3).toString().split('.')[1]
          this[data[sum]] = {
            dinar: parseInt(dinar) > 0 ? dinar : 0,
            fills: parseInt(fills) > 0 ? fills : 0,
          }
        }
      }
    },
    emptyServiceMenu() {
      this.service = ''
      $_('#service').selectpicker('val', '')
    },
    deleted(id) {
      this.items = this.items.filter(item => {
        return item.id !== id
      })
      http.popupMessage('success', 'تم إزالة البند بنجاح')
      this.calculateTotal()
    },
    pushWinch() {
      if (!!this.winchService && !!this.winchService.dinar) {
        this.service = this.winchService.type
        this.saveItem()
      }
    },
    pushEService() {
      if (!!this.eServices && !!this.eServices.dinar) {
        this.service = this.eServices.type
        this.saveItem()
      }
    },
    async refresh() {
      this.reset()
      await this.setInvoice()
    },
    reset() {
      this.items = []
      this.service = ''
      this.invoice = ''
      this.auction = ''
      this.sum = {}
      this.tax = {}
      this.total = {}
    }
  },
  async created() {
    await this.setInvoice()
    if (!!this.invoice)
      return await this.$router.replace({name: 'invoices.edit', params: {invoice: this.invoice.id}})
  },
  updated() {
    $_('#service').selectpicker('refresh')
  },
  mounted() {
    $_('#service').selectpicker('refresh')
  }
}
</script>
<style scoped>
.container {
  position: relative;
  padding: 1rem;
  background-color: white;
}

th {
  padding: 0.5rem;
}

h6 {
  padding: 0;
  margin: 0;
}

.invoice-canceled {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 10;
}

.border-bottom-dotted {
  border-bottom: 1px dotted black;
}

.jumbotron h6 {
  font-weight: bold;
  text-align: center;
}
</style>
